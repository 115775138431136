/*
 * 업무구분: 수익증권>전자서식>고객보관용서류수령방법선택_P
 * 화 면 명: MSPFS307P
 * 화면설명: 고객보관용서류수령방법선택 POPUP
 * 작 성 일: 2023.05.15
 * 작 성 자: 이성룡
 */
<template>
  <mo-modal
    class="fts-modal medium pop-beforeMain pop-verification"
    ref="modal"
    title="고객보관용 서류 수령방법 선택"
  >
    <template>
      <div class="wrap-modalcontents">
        <div class="wrap-table">
          <table class="table col-type th-wsN mb0">
            <tbody>
              <!-- <tr>
                <th>이메일</th>
                <td>
                  <div class="column gap10">
                    <div class="wrap-input row">
                      <mo-text-field
                        class="input-email01"
                        v-model="email01.value"
                        disabled
                      />
                      <span class="ico-between-email"> @ </span>
                      <mo-text-field
                        class="input-email02"
                        v-model="email02.value"
                        disabled
                      />
                    </div>
                    <div class="wrap-input row h20">
                      <mo-checkbox
                        :disabled="cEmail.disabled"
                        :checked="cEmailChecked"
                        v-model="cEmail.value"
                        >선택</mo-checkbox
                      >
                    </div>
                  </div>
                </td>
              </tr> -->
              <tr>
                <th>휴대폰(알림톡)</th>
                <td>
                  <div class="column gap10">
                    <div class="wrap-input row">
                      <mo-text-field
                        class="input-long"
                        v-model="cellPhone01.value"
                        disabled
                      />
                      <mo-text-field
                        class="input-long"
                        v-model="cellPhone02.value"
                        disabled
                      />
                      <mo-text-field
                        class="input-long"
                        v-model="cellPhone03.value"
                        disabled
                      />
                    </div>
                    <div class="wrap-input row h20">
                      <mo-checkbox
                        :disabled="cCellPhone.disabled"
                        :checked="cCellPhoneChecked"
                        v-model="cCellPhone.value"
                        >선택</mo-checkbox
                      >
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="wrap-desc mt16 bb0">
          <!-- <p>이메일/휴대폰(알림톡)은 본인명의만 신청 가능합니다.</p>
          <p>
            이메일/휴대폰(알림톡)으로 전달 실패 시, 우편으로 전달받으실 수
            있습니다.
          </p> -->
          <p>휴대폰(알림톡)은 본인명의만 신청 가능합니다.</p>
          <p>
            휴대폰(알림톡)으로 전달 실패 시, 우편으로 전달받으실 수
            있습니다.
          </p>
        </div>
        <div class="wrap-input mt16">
          <mo-checkbox
            :disabled="cPost.disabled"
            :checked="cPostChecked"
            v-model="cPost.value"
            >우편으로 전달 필요 시 신청</mo-checkbox
          >
        </div>
      </div>
    </template>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <!-- <mo-button primary size="large" @click="onClose"
          >닫기</mo-button
        > -->
        <mo-button primary size="large" @click="checkRecvMethod"
          >확인</mo-button
        >
      </div>
    </template>
    <!-- alert modal popup -->
    <mo-modal
      ref="alertPopup"
      class="fts-modal small"
      :title="lv_alertPopupObj.title"
    >
      <div class="column align-center">
        <h2 class="title" v-if="lv_alertPopupObj.contentTitle">
          {{ lv_alertPopupObj.contentTitle }}
        </h2>
        <span class="wrap-text">
          <span v-for="(row, i) in lv_alertPopupObj.content" :key="i">
            {{ lv_alertPopupObj.content[i]
            }}<br v-if="i < lv_alertPopupObj.content.length" />
          </span>
        </span>
      </div>
      <template slot="action">
        <div
          class="wrap-button button-page-bottom row"
          v-if="lv_alertPopupObj.type === 0"
        >
          <mo-button size="large" @click="fn_AlertPopupClose()">확인</mo-button>
        </div>
        <div
          class="wrap-button button-page-bottom row"
          v-if="lv_alertPopupObj.type === 1"
        >
          <mo-button size="large" @click="fn_AlertPopupClose()">취소</mo-button>
          <mo-button primary size="large" @click="fn_AlertPopupConfirm()"
            >확인</mo-button
          >
        </div>
      </template>
    </mo-modal>
    <mo-snackbar ref="stackableSnackbar" :duration="3000"></mo-snackbar>
  </mo-modal>
</template>

<script>
import FSCommUtil from "~/src/ui/fs/comm/FSCommUtil";
import FSMessageUtil from "~/src/ui/fs/comm/FSMessageUtil";
import FSServiceUtil from "~/src/ui/fs/comm/FSServiceUtil";
import TSInfoUtil from "~/src/ui/ts/comm/TSInfoUtil";

export default {
  name: "MSPFS307P",
  screenId: "MSPFS307P",

  components: {},

  props: {
    popupObj: { type: Object, default: null },
  },

  created() {},

  mounted() {
    this.$bizUtil.insSrnLog("MSPFS307P");
  },

  data() {
    return {
      eai: FSCommUtil.gfn_eaiCommObj(),
      email01: {},
      email02: {},
      cellPhone01: {},
      cellPhone02: {},
      cellPhone03: {},
      pAlert: {},
      cEmail: { value: false, disabled: true },
      cCellPhone: { value: false, disabled: true },
      cPost: { value: false, disabled: true },
      cEmailChecked: "",
      cCellPhoneChecked: "",
      cPostChecked: "",
      lv_alertPopupObj: {},      
    };
  },

  computed: {
    modal() {
      return this.$refs.modal;
    },
  },

  watch: {},

  methods: {
   onClose() {
     this.modal.close()
   },
    addSnackbar(msg) {
      this.$refs.stackableSnackbar.show({ text: msg });
    },
    fn_AlertPopup(obj) {
      this.lv_alertPopupObj = {};
      this.lv_alertPopupObj.type = obj.type;
      this.lv_alertPopupObj.title = ""; // 사용안함
      this.lv_alertPopupObj.contentTitle = ""; // 사용안함
      this.lv_alertPopupObj.content = obj.content;
      this.lv_alertPopupObj.closeFunc = function () {};
      this.lv_alertPopupObj.confirmFunc = function () {};
      if (obj.type === 1) {
        this.lv_alertPopupObj.closeFunc = obj.closeFunc;
        this.lv_alertPopupObj.confirmFunc = obj.confirmFunc;
      }
      this.$refs.alertPopup.open();
    },
    fn_AlertPopupClose() {
      if (!FSCommUtil.gfn_isNull(this.lv_alertPopupObj.closeFunc)) {
        this.lv_alertPopupObj.closeFunc.call(this);
      }
      this.$refs.alertPopup.close();
    },
    fn_AlertPopupConfirm() {
      if (!FSCommUtil.gfn_isNull(this.lv_alertPopupObj.confirmFunc)) {
        this.lv_alertPopupObj.confirmFunc.call(this);
      }
      this.$refs.alertPopup.close();
    },
    async fn_Open() {
      await this.fn_Init()
      this.modal.open()
    },

    fn_Close() {
      this.modal.close();
    },

    async fn_Init() {
      console.log("Parameter : ", JSON.stringify(this.popupObj));

      this.email01 = FSCommUtil.gfn_dataSet(1);
      this.email02 = FSCommUtil.gfn_dataSet(1);
      this.cellPhone01 = FSCommUtil.gfn_dataSet(1);
      this.cellPhone02 = FSCommUtil.gfn_dataSet(1);
      this.cellPhone03 = FSCommUtil.gfn_dataSet(1);

      this.cEmail = FSCommUtil.gfn_dataSet(1);
      this.cCellPhone = FSCommUtil.gfn_dataSet(1);
      this.cPost = FSCommUtil.gfn_dataSet(1);

      this.email01.value = "";
      this.email02.value = "";
      this.cellPhone01.value = "";
      this.cellPhone02.value = "";
      this.cellPhone03.value = "";
      this.cEmail.disabled = true;
      this.cEmail.value = false;
      this.cCellPhone.disabled = true;
      this.cCellPhone.value = false;
      this.cPost.value = false;

      let partTyp = this.$route.name.substr(3,2).toLowerCase()
      if(partTyp === 'fs') {          
        await this.fn_SearchListFS()
      } else {
        await this.fn_SearchListTS()
      }
    },

    // 고객정보를 직접 설정
    fn_SetCustInfoDirect(data) {      
      try {
        this.email01.value = !FSCommUtil.gfn_isNull(data.cust_emai_addr_encr) ? data.cust_emai_addr_encr : ''
        this.email02.value = !FSCommUtil.gfn_isNull(data.cust_emai_dm_nm) ? data.cust_emai_dm_nm : ''
        this.cellPhone01.value = !FSCommUtil.gfn_isNull(data.bman_celph_dstno_encr) ? data.bman_celph_dstno_encr : ''
        this.cellPhone02.value = !FSCommUtil.gfn_isNull(data.bman_celph_tlnum_no_encr) ? data.bman_celph_tlnum_no_encr : ''
        this.cellPhone03.value = !FSCommUtil.gfn_isNull(data.bman_celph_bkno_sno_encr) ? data.bman_celph_bkno_sno_encr : ''
        
        // 고객정보직접저장
        // data.saveTyp = 4
        // this.$bizUtil.fsUtils.saveCustInfo(this, data)

        this.cEmail.disabled =  FSCommUtil.gfn_isNull(data.cust_emai_addr_encr)
        this.cEmail.value = false
        this.cCellPhone.disabled = FSCommUtil.gfn_isNull(data.bman_celph_bkno_sno_encr)
        this.cCellPhone.value = false
        this.cPost.value = false
      } catch (e) {

      }
    },

    async fn_SearchListFS() {      
      let custInfo = this.getStore('fsStore').getters.getBasInfo.data.custInfo
      if (FSCommUtil.gfn_isNull(custInfo.bfcer_cust_no)) {
        this.fn_SetCustInfoDirect(custInfo)
        return
      }

      this.eai.lv_vm = this;
      this.eai.auth = "S";
      this.eai.commHeaderVO.eaiId = "C391_F10570293_S";
      this.eai.params = {
        bfcer_cust_no: custInfo.bfcer_cust_no,
      };

      console.log("F10570293 Parameter (fn_SearchListFS) : ", JSON.stringify(this.eai.params));
      FSServiceUtil.invokeInDirect(this.eai, function (res) {
        console.log("고객정보 조회 결과 ...");
        console.log("\t RESULT : ", res.data);
        console.log("\t 오류코드 : ", res.data.error_msg);
        console.log("\t 이메일 1 : ", res.data.cust_emai_addr_encr);
        console.log("\t 이메일 2 : ", res.data.cust_emai_dm_nm);
        console.log("\t 휴대폰 1 : ", res.data.bman_celph_dstno_encr);
        console.log("\t 휴대폰 2 : ", res.data.bman_celph_tlnum_no_encr);
        console.log("\t 휴대폰 3 : ", res.data.bman_celph_bkno_sno_encr);

        if (FSCommUtil.gfn_trim(res.data.error_msg) != "" &&res.data.error_msg.substring(0, 6) != "809900") {
          this.addSnackbar(FSMessageUtil.commMessage([], res.data.error_msg)[res.data.error_msg.substring(0, 6)])
          return
        }

        console.log("F10570293 (fn_SearchListFS) - res : ", JSON.stringify(res.data))

        // 고객정보저장
        this.$bizUtil.fsUtils.saveCustInfo(this, res.data)

        this.email01.value = res.data.cust_emai_addr_encr
        this.email02.value = res.data.cust_emai_dm_nm
        this.cellPhone01.value = res.data.bman_celph_dstno_encr
        this.cellPhone02.value = res.data.bman_celph_tlnum_no_encr
        this.cellPhone03.value = res.data.bman_celph_bkno_sno_encr

        this.cEmail.disabled = FSCommUtil.gfn_trim(res.data.cust_emai_addr_encr) == ""
        this.cEmail.value = false
        this.cCellPhone.disabled = FSCommUtil.gfn_trim(res.data.bman_celph_bkno_sno_encr) == ""
        this.cCellPhone.value = false
        this.cPost.value = false
      });
    },

    async fn_SearchListTS() {      
      console.log('fn_SearchListTS - csId : ', this.popupObj.csId)
      console.log('fn_SearchListTS - tacno : ', this.popupObj.tacno)
      if(FSCommUtil.gfn_isNull(this.popupObj.csId) && FSCommUtil.gfn_isNull(this.popupObj.tacno)){
        return
      }

      let params = {}
      let t_data = {}

      if(!FSCommUtil.gfn_isNull(this.popupObj.csId)){
        params = {
          reportParam: JSON.stringify({csId: this.popupObj.csId}),
          reportUrl: 'selectTFCP10000',
        }

        t_data = await TSInfoUtil.getReportInfo(this, params)
        if (!FSCommUtil.gfn_isNull(t_data) && t_data.errorCede !== '-1' && !FSCommUtil.gfn_isNull(t_data.tfcp10000Rst)) {
          let t_src = t_data.tfcp10000Rst
          console.log('fn_SearchListTS - tfcp10000Rst > ', t_src)

          let t_celph = ''
          let t_emailAddr = ''
          if ( !FSCommUtil.gfn_isNull(t_src.emailAddr) ){
            this.email01.value = t_src.emailAddr
            this.email02.value = t_src.domnNm
            t_emailAddr = t_src.emailAddr
            t_emailAddr += '@'
            t_emailAddr += t_src.domnNm
          }

          if ( !FSCommUtil.gfn_isNull(t_src.mvphSeq) ){
            this.cellPhone01.value = t_src.mvphBusiMnNo
            this.cellPhone02.value = t_src.mvphPofn
            this.cellPhone03.value = t_src.mvphSeq
            t_celph = t_src.mvphBusiMnNo
            t_celph += '-' + t_src.mvphBusiMnNo
            t_celph += '-' + t_src.mvphBusiMnNo            
          }

          let t_obj = t_src
          t_obj.celph = t_emailAddr
          t_obj.emailAddr = t_celph
          this.$bizUtil.tsUtils.saveCustInfo(this,t_src)

          this.cEmail.disabled = (FSCommUtil.gfn_trim(t_src.emailAddr) == "" || FSCommUtil.gfn_trim(t_src.domnNm) == "")
          this.cEmail.value = false
          this.cCellPhone.disabled = FSCommUtil.gfn_trim(t_src.mvphSeq) == ""
          this.cCellPhone.value = false
          this.cPost.value = false
        }
      }

      if(!FSCommUtil.gfn_isNull(this.popupObj.tacno) && !FSCommUtil.gfn_isNull(this.popupObj.bnkbSeq) && !FSCommUtil.gfn_isNull(this.popupObj.accSeq)){
        params = {
          reportParam: JSON.stringify({
              tacno:    this.popupObj.tacno,
              bnkbSeq:  this.popupObj.bnkbSeq,
              ctno:   Number(this.popupObj.accSeq)
          }),
          reportUrl: 'reportTFAC20000',
        }

        t_data = await TSInfoUtil.getReportInfo(this, params)        
        if (!FSCommUtil.gfn_isNull(t_data) && t_data.errorCede !== '-1' && !FSCommUtil.gfn_isNull(t_data.tfac20000Rst) ) {
          let t_src = t_data.tfac20000Rst
          console.log('fn_SearchListTS - tfac20000Rst > ', t_src)

          let t_celph = ''
          let t_emailAddr = ''
          if ( !FSCommUtil.gfn_isNull(t_src.emailAddr) ){
            this.email01.value = t_src.emailAddr
            this.email02.value = t_src.domnNm
            t_emailAddr = t_src.emailAddr
            t_emailAddr += '@'
            t_emailAddr += t_src.domnNm
          }

          if (!FSCommUtil.gfn_isNull(t_src.telNo)){ 
            let telNoArr = t_src.telNo.split('-')
            this.cellPhone01.value = telNoArr[0]
            this.cellPhone02.value = telNoArr[1]
            this.cellPhone03.value = telNoArr[2]
            t_celph = t_src.telNo
          }
          
          let t_obj = t_src
          t_obj.celph = t_emailAddr
          t_obj.emailAddr = t_celph
          this.$bizUtil.tsUtils.saveCustInfo(this,t_src)
          
          this.cEmail.disabled = (FSCommUtil.gfn_trim(t_src.emailAddr) == "" || FSCommUtil.gfn_trim(t_src.domnNm) == "")
          this.cEmail.value = false
          this.cCellPhone.disabled = FSCommUtil.gfn_trim(t_src.telNo) == ""
          this.cCellPhone.value = false
          this.cPost.value = false
        }
      }
    },

    checkRecvMethod() {
      console.log(
        "이메일.checked : [" +
          this.cEmail.value +
          "], 비활성화 : [" +
          this.cEmail.disabled +
          "]"
      );
      console.log(
        "휴대폰.checked : [" +
          this.cCellPhone.value +
          "], 비활성화 : [" +
          this.cCellPhone.disabled +
          "]"
      );
      console.log("우편.checked : [" + this.cPost.value + "]");
      // if (this.cEmail.disabled && this.cCellPhone.disabled) {
      if (this.cCellPhone.disabled) {
        console.log(
          "\t 이메일, 휴대폰이 모두 입력되지 않아 선택할 수 없는 경우 우편 전달이 필수 ..."
        );
        if (this.cPost.value == false) {
          this.addSnackbar("우편으로 전달을 신청해 주세요.");
          // this.fn_AlertPopup({type: 0, content: ['우편으로 전달을 신청해 주세요.']})
          return;
        }
      } else {
        console.log("\t 이메일, 휴대폰 중 선택 가능...");
        // if (this.cEmail.value == false && this.cCellPhone.value == false) {
        if (this.cCellPhone.value == false) {        
          this.addSnackbar("1가지 이상의 수령방법을 선택해 주세요.");
          // this.fn_AlertPopup({type: 0, content: ['1가지 이상의 수령방법을 선택해 주세요.']})
          return;
        }
      }

      this.fn_Callback();
      // this.fn_AlertPopup(0, {cancel:true, confirm:true, confirmFunc:this.fn_Callback, content:'전자서명을 진행합니다.'})
    },

    fn_Callback() {
      var res = {
        email01: this.email01.value,
        email02: this.email02.value,
        cellPhone01: this.cellPhone01.value,
        cellPhone02: this.cellPhone02.value,
        cellPhone03: this.cellPhone03.value,
        emailYN: this.cEmail.value ? "Y" : "N",
        cellPhoneYN: this.cCellPhone.value ? "Y" : "N",
        postYN: this.cPost.value ? "Y" : "N",
      };
      console.log("\t Response : " + JSON.stringify(res));
      this.$emit("fs-popup-callback", "MSPFS307P", res);
      this.modal.close();
    },
  },
};
</script>
<style scoped>
</style>
